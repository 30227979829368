import { APICore } from 'helpers/api/apiCore';
import role from 'helpers/hook';
import { Navigate } from 'react-router-dom';

const api = new APICore();
const Root = () => {
  if (api.isUserAuthenticated()) {
    return <Navigate to={`/support/mes-tickets`} />;
  } else {
    return <Navigate to={`/account/login`} />;
  }
};

export default Root;
